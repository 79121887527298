import React from 'react'
import { useSelector } from 'react-redux';

function Error401() {
  const user = useSelector((state) => state.user);
  return ( <>
        <div className="error">
            <h1>401</h1>
            <p>Cet espace est réservé aux administrateurs</p>

            {
                  user.roles.includes("ROLE_PARTENAIRE") ? <>
                <a href="#">
                  <button className="btn btn-1">se connecter en tant partenaire</button>
                  </a>
                </>
                : 
                <a href="https://teraalma.com/auth/login">
                <button className="btn btn-1">se connecter en tant utilisateur</button>
                </a>
                
            }
        </div>
    </> );
}

export default Error401;