import { faAdversal } from '@fortawesome/free-brands-svg-icons';
import { faBug, faChartLine, faGift, faMoneyBillTrendUp, faQrcode, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { NavLink } from 'react-router-dom';
import MobileMenu from '../../components/sections/mobileMenu';
import { useSelector } from 'react-redux';

function SiteBare() {
  const user = useSelector((state) => state.user);

  console.log(user.roles)
  const isNavLinkActive = (match, location) => {
        if (!match) {
          return false; // Si le lien ne correspond pas du tout, il n'est pas actif.
        }
      
        const { pathname } = location;
        return pathname === match.url; // Vérifie si le chemin correspond exactement.
      };
    return ( 
    <div className="sitebar d-none d-md-block">
        <ul>
          
        {
                  user.roles.includes("ROLE_ADMIN") ? <>
                <NavLink to="/dashboard/partenaires/list" isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faGift} className='me-2' />  Partenaires</li></NavLink>
                <NavLink to="/dashboard/clients/list" isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faUsers} className='me-2' />  Clients</li></NavLink>
                <NavLink to="/dashboard/partenaires/clients" isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faChartLine} className='me-2' />  Activitées</li></NavLink>
                </>
                :
                <NavLink to="#" isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faBug} className='me-2' />  Erreur</li></NavLink>
              
}
          </ul>
    </div> 
    );
}

export default SiteBare;