
function App() {


  return (
    <>
    <div className='section1 full'>
    </div>
      
      <div className="sectionHeader mt-5">
          <div className="title">
            <div className="content">
              <h2>Nos types de carte</h2>
              <p className='text-primary'> 
                Obtenez une carte et profitez de nos réductions.
              </p>
            </div>
          </div>
        </div>
    <div className='lastSection'>
      </div>
      
    </>
      );
}

export default App;
