import { faAdversal } from '@fortawesome/free-brands-svg-icons';
import { faBars, faBug, faChartLine, faGift, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';
import Logout from '../../funtions/logout';
import { useSelector } from 'react-redux';

function OffCanvasExample({ name, ...props }) {
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="btn btn-1 me-2">
        <FontAwesomeIcon icon={faBars} />
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-menu">
            <ul>
              {
                user.roles.includes("ROLE_ADMIN") ? (
                  <>
                    <NavLink 
                      to="/dashboard/partenaires/list" 
                      isActive={false} 
                      activeClassName="active" 
                      onClick={handleClose} // Close the menu on click
                    > 
                      <li>
                        <FontAwesomeIcon icon={faGift} className='me-2' />  Partenaires
                      </li>
                    </NavLink>
                    <NavLink 
                      to="/dashboard/clients/list" 
                      isActive={false} 
                      activeClassName="active" 
                      onClick={handleClose} // Close the menu on click
                    > 
                      <li>
                        <FontAwesomeIcon icon={faUsers} className='me-2' />  Clients
                      </li>
                    </NavLink>
                    <NavLink 
                      to="/dashboard/partenaires/clients" 
                      isActive={false} 
                      activeClassName="active" 
                      onClick={handleClose} // Close the menu on click
                    > 
                      <li>
                        <FontAwesomeIcon icon={faChartLine} className='me-2' />  Activitées
                      </li>
                    </NavLink>
                  </>
                ) : (
                  <NavLink 
                    to="#" 
                    isActive={false} 
                    activeClassName="active" 
                    onClick={handleClose} // Close the menu on click
                  > 
                    <li>
                      <FontAwesomeIcon icon={faBug} className='me-2' />  Erreur
                    </li>
                  </NavLink>
                )
              }
            </ul>
          </div>
          <Logout text="Se déconnecter" color="#000" />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function MobileMenu() {
  return (
    <>
      {['start'].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}
