import axios from 'axios';
import React, { useEffect, useState } from 'react'

function ClientList() {
    const [clients, setClients] = useState([])

    useEffect(() => {
        const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

        axios.get(process.env.REACT_APP_BASE_API + process.env.REACT_APP_CLIENT_LIST_ALL, config)
        .then((res) => {
            setClients(res.data.clients)
            // console.log(res.data.clients)
        })
    },[])
    return ( 
      <div className="table-responsive-sm">
        <table class="table table-bordered table-sm">
  <thead class="table-primary">
    <tr>
      <th scope="col">Nom Complet</th>
      <th scope="col">Email</th>
      <th scope="col">Tel</th>
      <th scope="col">Civility</th>
    </tr>
  </thead>
  <tbody>
    {
        clients?.reverse().map((client) => (
            <tr>
                <th scope="row">{client.name}</th>
                <td>{client.email}</td>
                <td>{client.tel}</td>
                <td>{client.civility}</td>
            </tr>
        ))
    }
  </tbody>
</table>
</div>

     );
}

export default ClientList;